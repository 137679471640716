export const cloudSidebaCategoryLabels = [
  'Bookmarks',
  'Smart Folders',
  'Folders',
  'Private',
  'Labels',
  'More',
  'Status',
  'Beta',
]

export const cloudSidebarOrderedCategoriesDelimiter = '|'
